<template>
  <div>
    <div style="position: relative">
      <div class="apply-bg"></div>
      <div class="apply-btn" @click="toSysterm">
        认证申请
        <img src="@assets/index/apply/toapply.png" alt="">
      </div>
    </div>
    <div class="title">
      <div>申请指南</div>
      <div class="bottom-line"></div>
    </div>
    <div>
      <div class="content">
        <div style="display: flex;margin-bottom: 16px">
          <label>【申请依据】</label>
          <div style="flex:1;display: flex;align-items: center;flex-wrap: wrap">
            <a
              v-for="(item,index) in fileData.rulesDocumentList"
              :key="index"
              target="_blank"
              :style="{'margin-left': index===2?'0':'0px'}"
              :href="item.file"><span
              class="icons"></span> {{ item.name }}{{ index < 2 ? '、' : '' }}</a>
          </div>
        </div>
        <p style="display: flex;align-items: center;"><label>【申请指南】</label>
          <a
            target="_blank"
            style="display: flex;align-items: center;margin-right: 10px"
            :href="fileData.applicationGuideDocumentPath">
            <span class="icons icons-pdf" v-if="fileData.applicationGuideDocumentName"></span>
            {{ fileData.applicationGuideDocumentName }}</a>
          <!--          <a-->
          <!--            style="display: flex;align-items: center;"-->
          <!--            href="https://mp.weixin.qq.com/s/D_aK_hUzsUbsEFo9LOlZGw"-->
          <!--            target="_blank"><span-->
          <!--            class="icons icons-link"></span> 申请操作指引 </a>-->
        </p>
        <p style="display: flex;align-items: center;"><label>【申请范围】</label>
          <a
            target="_blank"
            style="display: flex;align-items: center;">
            <span class="icons" v-if="fileData.applicationScopeDocumentName"></span>
            {{ fileData.applicationScopeDocumentName }}</a>
        </p>
        <p style="display: flex;align-items: center;"><label>【费用公示】</label>
          <a
            target="_blank"
            style="display: flex;align-items: center;margin-right: 10px"
            :href="fileData.applicationCostDisclosure">
            <span class="icons icons-pdf" v-if="fileData.applicationCostDisclosureName"></span>
            {{ fileData.applicationCostDisclosureName }}</a>
        </p>
        <!--        <p><label>【申请网址】</label><a :href="fileData.applicationWebsite" target="_blank">{{-->
        <!--            fileData.applicationWebsite-->
        <!--          }}</a>-->
        <!--        </p>-->
      </div>
    </div>
    <div class="title">
      <div>申请流程</div>
      <div class="bottom-line"></div>
    </div>
    <div class="progress">
      <img src="@assets/index/apply/progress-none.png" alt="" width="100%" height="100%">
    </div>
    <div class="now-click" @click="toSysterm">
      已了解申请规则，立即申请
      <img src="@assets/index/apply/toapply.png" alt="">
    </div>
    <div class="gzh-tips">
      <div style="display: flex;align-items: center">
        <img src="@assets/index/apply/gzh-tips.png" alt="">
        <div>对认证申请有疑问的企业代表，可关注公众号“{{ webData.weixinPublicAccountName }}”进行了解</div>
      </div>
      <img :src="webData.weixinVideoImageQrCode" alt="" width="125" height="125">
    </div>
  </div>
</template>

<script>
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'
  import { getWebsiteInfo } from '@api/websiteInfo'

  export default {
    name: 'Application',
    data() {
      return {
        webData: {},
        fileData: {}
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        this.getWebData()
        this.getFileData()
      },
      getFileData() {
        getAction('/api/v1/dms/certificationApplicationConfig/all').then((res) => {
          if (res.success) {
            if (res && res.list && res.list.length > 0) {
              this.fileData = res.list[0]
              this.fileData.applicationCostDisclosure = 'https://minio.wellaudit.cn/bay-area/gbacpc/%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%94%B6%E8%B4%B9%E6%A0%87%E5%87%86%E5%85%AC%E7%A4%BA.pdf'
              this.fileData.applicationCostDisclosureName = '湾区认证收费标准公示.pdf'
              this.fileData.rulesDocumentList = this.fileData.applicationRulesDocumentPath.split(',').map((item, index) => {
                // 根据地址提取文件名,去掉_分割最后一部分
                let name = item.split('/').pop()
                const parts = name.split('_')
                parts.pop() // 移除最后一个元素
                return {
                  name: this.fileData.applicationRulesDocumentName.split(',')[index],
                  file: item
                }
              })
            } else {
              this.fileData = {}
            }
          } else {
            this.fileData = {}
            this.fileData.applicationCostDisclosure = 'https://minio.wellaudit.cn/bay-area/gbacpc/%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%94%B6%E8%B4%B9%E6%A0%87%E5%87%86%E5%85%AC%E7%A4%BA.pdf'
            this.fileData.applicationCostDisclosureName = '湾区认证收费标准公示.pdf'
            message.error(res.message)
          }
        }).catch((err) => {
          this.fileData = {}
          this.fileData.applicationCostDisclosure = 'https://minio.wellaudit.cn/bay-area/gbacpc/%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%94%B6%E8%B4%B9%E6%A0%87%E5%87%86%E5%85%AC%E7%A4%BA.pdf'
          this.fileData.applicationCostDisclosureName = '湾区认证收费标准公示.pdf'
          message.error(err.message)
        })
      },
      getWebData() {
        getWebsiteInfo().then(res => {
          if (!res.success) {
            console.error(res.message)
            return
          }
          this.webData = res.result[0]
        })
      },
      toSysterm() {
        // this.$message.info('系统正在开发中...')
        window.open(this.fileData.applicationWebsite, '_blank')
      }
    }
  }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .title {
    margin: 33px 0;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  .apply-bg {
    width: 100%;
    height: 560px;
    background: url("~@assets/index/apply/applicationbg.png") no-repeat;
    background-size: 100% 100%;
    opacity: 1;
    margin-top: 85px;
  }

  .apply-btn {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 80px;
    border-radius: 20px;
    background-color: #6abfff69;
    color: rgba(250, 250, 250, 1);
    font-size: 30px;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }

    img {
      margin-left: 5px;
    }
  }
}

@media (max-width: 768px) {
  .title {
    margin: 15px 0;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  .apply-bg {
    width: 100%;
    height: 200px;
    background: url("~@assets/index/apply/applicationbg.png") no-repeat;
    background-size: 100% 100%;
    opacity: 1;
    margin-top: 85px;
  }

  .apply-btn {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 45px;
    border-radius: 10px;
    background-color: @primary-color;
    color: rgba(250, 250, 250, 1);
    font-size: 16px;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }

    img {
      width: 25px;
      height: 25px;
      margin-left: 5px;
    }
  }
}

@media (min-width: 768px) {
  .content {
    width: 1440px;
    font-size: 20px;
    margin: 50px auto 45px;
  }
}

@media (max-width: 768px) {
  .content {
    width: 100%;
    font-size: 14px;
    margin: 20px auto 25px;
    padding: 0 10px;
  }
}


.content label {
  font-weight: bold;
  margin-right: 20px;
  color: #000;
}

.icons {
  display: inline-block;
}

.icons-pdf {
  width: 30px;
  height: 30px;
  background: url('~@/assets/index/apply/pdf.png') no-repeat;
  background-size: 100% 100%;
}

.icons-link {
  background: url('~@/assets/index/apply/link.png') no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .progress {
    width: 1440px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .progress {
    width: 100%;
    margin: 0 auto;
  }
}


@media (min-width: 768px) {
  .now-click {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: 80px;
    border-radius: 20px;
    background-color: @primary-color;
    color: rgba(250, 250, 250, 1);
    font-size: 30px;
    text-align: center;
    border: 1px solid rgba(187, 187, 187, 1);
    cursor: pointer;
    margin: 35px auto 40px;

    &:hover {
      opacity: 0.85;
    }

    img {
      margin-left: 5px;
    }
  }

  .gzh-tips {
    width: 1440px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  .now-click {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 45px;
    border-radius: 10px;
    background-color: @primary-color;
    color: rgba(250, 250, 250, 1);
    font-size: 16px;
    text-align: center;
    border: 1px solid rgba(187, 187, 187, 1);
    cursor: pointer;
    margin: 35px auto 40px;

    &:hover {
      opacity: 0.85;
    }

    img {
      width: 25px;
      height: 25px;
      margin-left: 5px;
    }
  }

  .gzh-tips {
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 20px 10px;

    img {
      width: 70px;
      height: 70px;
    }
  }
}


.link {
  display: flex;
  align-items: center;
  margin-left: 140px
}
</style>
